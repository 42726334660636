import React from "react"

import * as crosshairTileStyles from "./crosshairTile.module.scss" 

class CrosshairTile extends React.Component {
  render() {
    const { children } = this.props
    return (
        <div className={crosshairTileStyles.fullSize}>
            <div className={crosshairTileStyles.lines}>
                <div className={crosshairTileStyles.line1}/>
                <div className={crosshairTileStyles.line2}/>
            </div>
            <div className={crosshairTileStyles.fullSize}>
                {children}
            </div>
            <div className={crosshairTileStyles.lines}>
                <div className={crosshairTileStyles.line3}></div>
                <div className={crosshairTileStyles.line4}></div>
            </div>
        </div>
    )
  }
}

export default CrosshairTile
